import React from 'react';
import type { NextRouter } from 'next/router';
import { useRouter } from 'next/router';
import { AuthPage, selectUser } from 'modules/auth';
import withRedux from 'common/page-layout/withRedux';
import { routeTo, routeAs } from 'common/utils/routes';
import { useAppSelector } from 'common/hooks/state-hooks';
import Layout from 'common/page-layout/Layout';

const Login = () => {
  const user = useAppSelector(selectUser);
  const router: NextRouter = useRouter();

  if (user) {
    router.replace(routeTo.read, routeAs.read, { shallow: false });
    return null;
  }

  return (
    <Layout>
      <AuthPage
        goToSignIn={() => router.push(routeTo.login, routeAs.login)}
        goToSignUp={() => router.push(routeTo.signup, routeAs.signup)}
      />
    </Layout>
  );
};


export default withRedux(Login, { ssr: false });
